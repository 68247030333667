+function($, window){ 'use strict';
    $(function(){
        //AJAX token
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        var locale = $("html").attr("lang");
        if(locale != 'en'){
            $("[data-time][data-format]").each((i, el) => {
                let $this = $(el);
                let rawDate = $this.data('time')
                let d = Date.parse(rawDate);
                let format = $this.data('format');
                if(d && format){
                    var translatedDate;
                    if(format == "m hh:mm"){
                        translatedDate = d.toString("m") + " " + d.toString("hh:mm");
                    }else{
                        translatedDate = d.toString(format);
                    }
                    $this.text(translatedDate);
                }
            });
        }
        $('.tooltip-message').tooltipster({
            side: "right",
            trigger: 'custom',
            triggerOpen: {
                mouseenter: true,
                touchstart: true
            },
            triggerClose: {
                click: true,
                scroll: true,
                tap: true
            }
        });
        $(".list__item--has-child").click(function(e){
            e.preventDefault();
            $("ul", $(this)).toggle();
            $(this).toggleClass('list__item--child-active');
        });
        $("a.subscribe-email").click(e => {
            e.preventDefault();
            swal({
                title: "Email Notification",
                text: "Please fill in your email to get email notifications when there are new or updated incidents and notices.",
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                confirmButtonText: "Subscribe",
                animation: "slide-from-top",
                inputPlaceholder: "Email Address",
                showLoaderOnConfirm: true,
            }, (inputValue) => {
                var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if(inputValue === false) return false;
                if(inputValue === "" || !re.test(inputValue)) {
                    swal.showInputError("Please fill in your email");
                    return false
                }
                $.post("/subscribe/email", {email_address: inputValue})
                    .done( res => {
                        console.log(res);
                        if(res.code && res.code == 200){
                            swal("Confirmation Email Sent", "Please follow the link in it to confirm your subscription", "success");
                        }
                    })
                    .fail( err => {
                        console.log(err);
                        if(err.responseText){
                            var m = $.parseJSON(err.responseText);
                            sweetAlert("Subscription Error", m.message, "error");
                        }
                    });
            });
        });
    });
}(jQuery, window);
